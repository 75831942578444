import React from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import ROLES from "utils/roles";
import { TableBody, TableCell, TableRow, TableHead, Table, Typography, Chip, Button, IconButton } from "@material-ui/core";
import AddUser from "components/AddUser/AddUser";
import EditUser from "components/EditUser/EditUser";
import { Edit } from "@material-ui/icons";
import { getApolloClient } from "utils/apolloClientFactory";
import { GET_GROUP, ADD_USER_TO_GROUP, CREATE_USER } from "graphql/queries";

import { formatDateLong, formatDateShort } from "utils";

const kBatchUserCreationNumber = 800;
class UsersSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: window.covirtua_current_user,
      users: [],
      licenses: [],
      total_accounts: 0,
      user_accounts: 0,
      user_licenses: 0,
      user_without_license: 0,
      total: 0,
      assigned: 0,
      available: 0,
      userToEdit: {},
      add_user: false,
      edit_user: false,
      targetGroupId: this.props.targetGroupId || null,
    };
  }

  getUsers = () => {
    const groupID = this.state.targetGroupId || (this.state.user && this.state.user.group && this.state.user.group._id);

    if (groupID) {
      getApolloClient().then((client) => {
        client
          .query({
            query: GET_GROUP,
            variables: { groupID },
          })
          .then((response) => {
            const group = response.data.getGroup;

            const findLicense = (id) => group.licenses.find((license) => license.attributedTo && license.attributedTo._id === id);

            this.setState({
              users:
                group.users.map((user) => ({
                  ...user,
                  license: findLicense(user._id),
                })) || [],
              licenses: group.licenses || [],
              total_accounts: group.users.length,
              user_accounts: group.users.filter((user) => user.role.includes(ROLES.USER)).length,
              user_licenses: group.users.filter((user) => user.license).length,
              total: group.licenses.length,
              assigned: group.licenses.filter((license) => license.attributedTo).length,
            });
            this.setState({
              user_without_license: this.state.users.filter((user) => !user.license).length,
              available: this.state.total - this.state.assigned,
            });
          });
      });
    }
  };

  componentDidMount() {
    this.getUsers();
  }

  handleModalAddUser = (bool) => {
    this.setState({ add_user: bool });
    if (!bool) {
      this.getUsers();
    }
  };

  handleModalEditUser = (bool, user) => {
    this.setState({ edit_user: bool });

    if (bool) {
      this.setState({ userToEdit: user });
    } else {
      this.setState({ userToEdit: {} });
      this.getUsers();
    }
  };

  handleUserBatchCreation = () => {
    for (let index = 1; index <= kBatchUserCreationNumber; index += 1) {
      const patientFirstName = `sujet${  index}`;
      const patientLastName = "etude";
      const patientEmail = `sujet${  index  }@covirtua.com`;
      const password_part1 = Math.floor(Math.sqrt(Math.abs(2450 * index * index * index - 457 * index + 158))) % 455;
      const password_part2 = Math.floor(Math.sqrt(Math.abs(1253 * index * index * index - 3383 * index + 554))) % 540;
      const password = `${password_part1  }covirtua${  password_part2}`;
      setTimeout(() => {

        getApolloClient().then((client) => {
          client
            .mutate({
              mutation: CREATE_USER,
              variables: {
                email: patientEmail,
                role: [ROLES.USER],
                firstName: patientFirstName,
                lastName: patientLastName,
                profession: "",
                service: "",
                phoneNumber: "",
                type: "",
                password
              },
            })
            .then((responseCreateUser) => {
              client
                .mutate({
                  mutation: ADD_USER_TO_GROUP,
                  variables: {
                    userId: responseCreateUser.data.createUser._id,
                    groupId: this.state.user.group._id,
                  },
                })
                .then((responseAddUser) => {
                  // this.handleClose(true);
                  // this.handleSnackbar(true);
                });
            })
            .catch(e => {
              // this.handleClose();
              // VERY VERY DIRTY WAY
              // waiting to find a better way to handle apollo errors!
              if (e.graphQLErrors) {
                const groupName = e.message.replace(/(^.*\[|\].*$)/g, '');
                this.handleErrorSnackbar(true, this.state.email, groupName);
              }

            });
        });
      }, 200 * index); // do not send all requests simultaneously...
    };
  }

  noAccess = () => this.state.user && !this.state.user.group;

  render() {
    const { t } = this.props;
    const {
      user,
      users,
      total_accounts,
      user_accounts,
      user_licenses,
      user_without_license,
      add_user,
      edit_user,
      userToEdit,
      total,
      assigned,
      available,
    } = this.state;

    return this.noAccess() ? (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {t("note.complete_establishment")}
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={40}>
        <Grid item>
          <Typography variant="h4">{t("titles.users_summary")}</Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => {
              this.handleModalAddUser(true);
            }}
          >
            {t("create_account")}
          </Button>
          {this.state.user && this.state.user.role.includes(ROLES.ADMIN) && <Button
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
            onClick={() => {
              this.handleUserBatchCreation(true);
              }}
          >
              creer 800 Comptes          
          </Button>
            }
        </Grid>
        <Grid container item spacing={8}>
          <Grid item xs={12}>
            <Grid container spacing={16}>
              <Grid item>
                <Chip label={t("account.nb_total") + total_accounts} color="primary" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label={t("account.nb_user_accounts") + user_accounts} color="primary" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label={t("account.nb_user_licenses") + user_licenses} color="primary" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label={t("account.nb_user_without_license") + user_without_license} color="primary" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={16}>
              <Grid item>
                <Chip label={t("license_stats.nb_total") + total} color="secondary" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label={t("license_stats.nb_assigned") + assigned} color="secondary" variant="outlined" />
              </Grid>
              <Grid item>
                <Chip label={t("license_stats.nb_available") + available} color="secondary" variant="outlined" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("fields.first_name")}</TableCell>
                <TableCell>{t("fields.last_name")}</TableCell>
                <TableCell>{t("role")}</TableCell>
                <TableCell>{t("license_type")}</TableCell>
                <TableCell>{t("license_expiration")}</TableCell>
                <TableCell>{t("last_connection")}</TableCell>
                <TableCell>{t("actions")}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell component="th" scope="row">
                    {user.firstName}
                  </TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.role.length ? `${user.role.map((role) => t(`roles.${role}`))}` : "-"}</TableCell>
                  <TableCell>{user.license ? user.license.limitedTo : "-"}</TableCell>
                  <TableCell>{user.license ? formatDateShort(user.license.expirationDate) : "-"}</TableCell>
                  <TableCell>{user.lastLogin ? formatDateLong(user.lastLogin) : "-"}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => this.handleModalEditUser(true, user)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        {userToEdit._id && (
          <EditUser open={edit_user} user={userToEdit} groupId={user.group._id} onClose={() => this.handleModalEditUser(false)} />
        )}
        <AddUser open={add_user} onClose={() => this.handleModalAddUser(false)} />
      </Grid>
    );
  }
}

export default withTranslation()(UsersSummary);
